import React from 'react'
import ComparisonTemplate from '../../components/Comparison/ComparisonTemplate'

import hellobarLogo from '../../images/comparison-logo-images/hellobar.png'

const HelloBar = () => {

    const websiteIntegrationArray = [
        {
            title: 'WordPress Plugin',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Shopify App',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Google Tag Manager',
            optinly: true,
            integrationApp: true
        },
    ]

    const integrationArray = [
        {
            title: 'MailChimp',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'MailerLite',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'SendGrid',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'ConvertKit',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Zapier',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Sendinblue',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Campaign Monitor',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Intercom',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Integrately',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Pabbly',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Moosend',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Automizy',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Drip',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Integromat',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Webhooks',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'SendFox',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Klaviyo',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'ActiveCampaign',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'GetResponse',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Customer.io',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Hubspot',
            optinly: true,
            integrationApp: true
        },
    ]

    const pricingArray = [
        {
            title: 'Free Plan',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Highest Paid Plan',
            optinly: '$25/Month',
            integrationApp: '$99/Month'
        },
        {
            title: 'Unlimited Page Views',
            optinly: true,
            integrationApp: false
        },
    ]

    const campaignsArray = [
        {
            title: 'Lightbox Popup ',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Fullscreen Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Floating Bars',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Spin the Wheel Campaign',
            optinly: true,
            integrationApp: false
        },
        {
            title: 'Slide-in',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Sidebar Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Inline/After Post Campaigns',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Phone Field',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Countdown Timer',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Success View',
            optinly: true,
            integrationApp: true
        },
        {
            title: 'Smart Success Display for Subscribed Visitors',
            optinly: true,
            integrationApp: false
        },
    ]

    return (
        <>
            <ComparisonTemplate 

                metaTitle="Optinly vs Hello Bar Plug-in"
                metaDescription="Compare Optinly with Hello Bar Plug-in and discover the unique features that set it apart. Find out why Optinly is the best choice for your pop-up needs."

                otherProductLogo={hellobarLogo} //PLEASE IMPORT THE LOGO HERE LIKE THIS

                integrationApp="Hello Bar"
                homeTitle="Optinly - The Best Alternative to Hello Bar"
                homeDescription="Perfect alternative to Hello Bar. More Conversions and revenue without having to paymore. Explore what makes Optinly the best alternative to Hello Bar."

                websiteIntegrationArray={websiteIntegrationArray}
                integrationArray={integrationArray}
                pricingArray={pricingArray}
                campaignsArray={campaignsArray}

                FAQQuestion1="Do both Hello Bar and Optinly have free plans?"
                FAQAnswer1="No. Hello Bar does not have a free plan. Whereas Optinly comes with a forever-free plan."

                FAQQuestion2="Can I create a spin-the-wheel coupon popup with both Optinly and Hello Bar?"
                FAQAnswer2="Yes. You can create spin wheel coupon popups with both the popup plugins. The only difference is pricing - Optinly offers you spin to win popups at $25 while Hello Bar costs you $80 per month."

                FAQQuestion3="Which tool has a clean User Interface?" 
                FAQAnswer3="Both Optinly and Hello Bar are extremely good when it comes to user interface and design. The best thing about Optinly is its goal-based approach which lets you create campaigns in less than 4 simple steps."

                FAQQuestion4="Which tool allows me to create exit-intent popups?"
                FAQAnswer4="You can add exit triggers to your popup campaigns using both Optinly and Hello Bar. The only difference being, Optinly’s free version has exit triggers while Hello Bar costs users $47 per month."

                FAQQuestion5="Which popup plugin offers unlimited page views?"
                FAQAnswer5="Optinly does. Both Optinly’s free version and paid version don’t have a cap on the number of sessions and pageviews. Whilst when you use Hello Bar the limit on the number of page views increases as the pricing increases."
            />
        </>
    )
}

export default HelloBar
